*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  padding: 110px 20px 0 20px;
  max-width: 1800px;
  margin: auto;
}
@media screen and (max-width:800px) {
  body {
    padding: 110px 5px 0 5px;
  }
}

/* ======  GENERAL COMPONENTS ========= */
.btn-contact {
  display: block;
  width: 180px;
  height: 40px;
  border: none;
  background-color: #0089BF;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  transition: 0.5s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px;
}
.btn-contact:hover {
  background-color: white;
  color: #0089BF;
}

.subTitle {
  font-size: 30px;
  font-weight: bold;
  color: #072A40;
}
p {
  color: gray;
}

.iconCheck {
  color: #0089BF;
  font-size: 70px;
}
.check {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.check h2 {
  font-size: 25px;
  font-weight: bold;
  color: #072A40;
}
.check p {
  width: 95%;
}


.comment {
  background-color: white;
  border-radius: 20px;
  padding: 20px 20px;
}
.comment img {
  width: 100px;
  margin-bottom: 20px;
}
.comment .iconStar {
  color: #0089BF;
  font-size: 25px;
  margin: 5px;
}
.comment #name {
  font-size: 20px;
  font-weight: bold;
}
.comment #comment {
  font-size: 15px;
  max-width: 90%;
  margin: 0 auto 20px auto;
}


.footer {
  background-color: #072A40;
  padding: 25px;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
}
.footer .logo {
  width: 200px;
  margin-left: -5px;
}
.footer h1 {
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 0;
}
.footer h2 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}
.footer p {
  color:  white;
  margin-bottom: 5px;
}
.footer .iconPhone, 
.footer .iconMail {
  margin-right: 10px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer #right {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer .iconFacebook, 
.footer .iconInstagram {
  font-size: 35px;
  margin: 15px;
}
.footer .menu {
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 70px;
}
.footer .menu .footer-btn {
  text-decoration: none;
  color: white;
  font-size: 15px;
}
.footer .menu .footer-btn:hover {
  color: #0089BF;
}

.newsLetter {
  background-image: url("/src/images/stock6.png");
  background-color: #cccccc; 
  height: 500px;
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.newsLetter h2 {
  font-size: 3em;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Adjust the shadow values as needed */
}
.newsLetter input {
  display: block;
  width: 50%;
  max-width: 500px;
  height: 50px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  margin: 20px;
  text-align: center;
  outline: none;
  color: gray;
}

.newsLetter button {
  background-color: #0089BF;
  color: white;
  border: none;
  width: 80%;
  max-width: 300px;
  font-size:20px;
  height: 40px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.newsLetter button:hover{
  background-color: white;
  color: #0089BF;
}


.inflatable {
  display: inline-block;
  width: 300px;
  border-radius: 20px;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid lightgray;
  margin: 10px;
  white-space: normal;
}
.inflatable img {
  width: 100%;
  height: 180px;
  object-fit: contain;
  margin-bottom: 10px;
  z-index: 2;
}
.inflatable #name-price{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inflatable #name {
  color: #072A40;
  font-weight: bold;
  font-size: 18px;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:clip;
}
.inflatable #price {
  background-color: #0089BF;
  color: white;
  padding: 0 10px;
  border-radius: 5px;
}
.inflatable #description {
  font-size: 12px;
  text-align: left;
  max-width: 300px;
  height: 100px;
  overflow: scroll;
}
.inflatable #dimentions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  margin-bottom: 8px;
}
.inflatable .dimention {
  border: 1px solid lightgray;
  border-radius: 5px;
  text-align: center;
}
.inflatable .dimention .value {
  font-size: 15px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.inflatable .dimention .type {
  font-size: 15px;
  color: #0089BF;
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: 0px;
}

.react-calendar {
  min-width: 350px;
  width: 350px;
  max-width: 350px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
  padding: 20px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  /* background-color: #2c3e50; */
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.react-calendar__navigation button {
  background-color: transparent;
  color: gray !important;
  height: 100%;
  font-size: 15px;
  border: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  color: #0089BF !important;
  
}
.react-calendar__navigation button[disabled] {
  /* background-color: #2c3e50; */
}
.react-calendar__month-view__weekdays {
  text-align: center;
  /* text-transform: uppercase; */
  font-weight: 400;
  font-size: 15px;
  /* background-color: #0089BF; */
  color: gray;
}

abbr[title] {
  text-decoration: none;
  font-size: 13px;
  margin-left: -5px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
} */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 40px;
  text-align: center;
  padding: 5px 10px;
  background: none;
  /* border: 2px solid white; */
  border: none;
  font-size: 13px;
  border-radius: 10px;
  margin: 2px !important;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gray;
}
.react-calendar__tile:disabled {
  text-decoration:line-through;
  background-color: rgb(244, 244, 244);
  border: 1px solid white
  /* color: red; */
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  /* background: #2c3e50; */
  color: #0089BF;
  border: 1px solid #0089BF;
}


.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@media screen and (max-width:800px) {
  .react-calendar__tile {
    height: 40px;
    font-size: 15px;
  }
}

 .prices {
    font-size: 10px;
  }

  .paymentGateway .payment-popup {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 700px;
    height: 520px;
    border-radius: 20px;
    padding: 20px 30px;
    overflow-y: scroll;
    z-index: 999;
  }
  .paymentGateway .payment-popup #logo{
    max-height: 70px;
    display: block;
    margin: auto;
  }
  .paymentGateway .payment-popup h3 {
    border-bottom: 1px solid lightgray;
    color: gray;
    font-size: 15px;
    padding-bottom: 5px;
  }
  .paymentGateway .payment-popup p {
    margin: 3px;
    padding-bottom: 2.5px;
  }
  .paymentGateway .bookingDetails {
    display: grid;
    grid-template-columns: auto 60%;
    gap: 30px;
    align-items: center;
  }
  .paymentGateway .bookingDetails #inflatable{
    display: block;
    width: 100%;
  }
  .paymentGateway .amount-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    text-align: center;
    margin: 15px 0;
  }
  .paymentGateway .amount-options p {
    border-radius: 5px;
    border: 1px solid transparent;
    color: gray;
    padding: 5px;
    transition: 0.3s;
    cursor: pointer;
    border: 1px solid lightgray;
  }
  .paymentGateway .amount-options p:hover {
    border: 1px solid #0089BF;
    color: #0089BF;
  }
  
  .paymentGateway .amount-options .selected {
    color: white;
    background-color: #0089BF;
    border: 1px solid #0089BF;
  }
  .paymentGateway .amount-options .selected:hover {
    color: white;
  }
  .paymentGateway h4 {
    font-size: 10px;
    margin-bottom: 0;
    color: #0089BF;
  }
  .paymentGateway #disclaimer {
    font-size: 10px;
  }
  .paymentGateway .three-col  {
    display: grid;
    grid-template-columns: 20px auto 20px;
    gap: 5px;
    align-items: center;
  }
  .paymentGateway .iconChev {
    color: gray;
  }
  .paymentGateway .iconChev:hover {
    color: #0089BF;
  }
  .paymentGateway .damageWaiver {
    margin-bottom: 10px;
  }
  .paymentGateway #btnPay {
    display: block;
    margin: 45px auto 0 auto;
    width: 50%;
    height: 40px;
    background-color: #0089BF;
    color: white;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .paymentGateway #btnPay:hover {
    background-color: white;
    color: #0089BF;
  }

  .paymentGateway .confirm-contract {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }



  .paymentConfirmation {
    display: flex;
    gap: 20px;
  }
  .paymentConfirmation #confirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .paymentConfirmation #confirmation p{
   line-height: 15px;
  }
  .paymentConfirmation .iconConfirmation {
    color: #3bd304;
    font-size: 80px;
  }
  .paymentGateway #failed {
    color: red;
    font-weight: bold;
  }
  .paymentGateway .paymentMethods{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
  }
  .paymentGateway .paymentMethod{
    display: grid;
    grid-template-columns:20px 20px auto;
    align-items: center;
    gap: 25px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
    width: 100%;
  }
  .paymentGateway .cashBookingConfirmation {
    display: grid;
    grid-template-columns: 100px auto;
    gap: 20px;
    align-items: center;
    padding: 0 20px;
    margin-top: 20px;
  }
  .paymentGateway .cashBookingConfirmation .iconConfirmation{
    font-size: 80px;
    color: #3bd304;
  }
  .paymentGateway .iconCash {
    color: gray;
    font-size: 30px;
  }

  .paymentGateway .blocking-btn {
    padding: 30px;
    margin-top: -45px;
    background-color: white;
  }

  .paymentMethod .iconCheck {
    font-size: 17px;
    text-align: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    line-height: 19px;
    color: white;
  }


  .paymentGateway .specific-time {
    /* border: 1px solid red; */
    margin-bottom: 20px;
  }
  .paymentGateway .specific-time p{
    font-size: 10px;
  }
  .paymentGateway .specific-time .grid-specificTime {
    display: grid;
    grid-template-columns: auto 30%;
    gap: 5px;
  }
  .paymentGateway .specific-time select{
    width: 100%;
    border: 1px solid lightgray;
    padding-left: 10px;
    height: 40px;
    border-radius: 5px;
    color: gray;
    outline: none;
    font-size: 15px;
  }
  .paymentGateway .specific-time input[type='time']{
    width: 100%;
    border: 1px solid lightgray;
    padding-left: 10px;
    height: 40px;
    border-radius: 5px;
    color: gray;
    outline: none;
    font-size: 15px;
  }

  @media screen and (max-width:800px) {
    .payment-popup {
      width: 100vw !important;
      height: 100vh !important;
      bottom: 0 !important;
      left: 0 !important;
      top: 100px !important;
      transform: none !important;
      border-top: 20px solid white;
      padding-bottom: 250px !important;
    }
    .payment-popup .bookingDetails {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      align-items: self-start;
    }
    .payment-popup .amount-options {
      display: flex;
    }
    .payment-popup .paymentConfirmation {
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
    .payment-popup #btnPay {
      width: 80% !important;
    }
    .payment-popup .amount-options p {
      display: flex;
      align-items: center;
      justify-content: center; 
    }

    .paymentGateway .paymentMethods{
      flex-direction: column;
    }
    
  }

  #closePaymentGateway {
    display: block;
    width: 300px;
    height: 35px;
    margin: auto;
    border: none;
    border-radius: 10px;
    background-color: #0089BF;
    color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.3s;
  }
  #closePaymentGateway:hover {
    background-color: white;
    color: #0089BF;
  }
  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.375);
    z-index: 998;
  }
  .form-creditCard {
    width: 100%;
  }
 
  .form-creditCard {
    margin-top: 20px;
  }
  .form-creditCard #btnPay {
    display: block;
    margin: 45px auto 0 auto;
    width: 100%;
    height: 40px;
    background-color: #0089BF;
    color: white;
    border: 1px solid #0089BF;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .form-creditCard #btnPay:hover {
    background-color: white;
    color: #0089BF;
  }
  .form-creditCard .iconCard {
    margin-right: 10px;
    font-size: 15px;
  }


  .btn-checkInflatables {
    display: block;
    width: 230px;
    height: 40px;
    border: none;
    background-color: white;
    color: #0089BF;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    text-decoration: none;
    transition: 0.5s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
  }
  .btn-checkInflatables:hover {
    background-color: #0089BF;
    color: white;
  }

  .btn-readMore {
    display: block;
    width: 100%;
    height: 30px;
    margin: auto;
    border: none;
    background-color: #0089BF;
    color: white;
    border-radius: 8px;
    margin: 5px auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    text-decoration: none;
    line-height: 30px;
  }
  .btn-readMore:hover {
    background-color: white;
    color: #0089BF;
  }


/* =========== HEADER  =========  */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: grid;
  grid-template-columns: 200px auto;
  height: 80px;
  align-items: center;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.header img {
  width: 100%;
  margin-left: 10px;
}
.header .navBtns {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1px;
}
.header .navBtns .btn  {
  color: gray;
  text-decoration: none;
  font-weight: bold;
  border-radius: 0px;
  line-height: 80px;
  background-color: white;
  height: 80px;
  padding: 0 30px;
  border: none;
}
.header .navBtns .active, 
.header .navBtns :hover  {
  background-color: #0089BF;
  color: white;
}
.header .cartIcon {
  font-size: 20px;
}
.nav-mobile-menu {
  display: none;
  border: none;
  background-color: transparent;
  width: fit-content;
  margin-left: auto;
  margin-right: 20px;
  font-size: 30px;
  color: gray;
}
.nav-mobile-menu :hover {
  color: #0089BF;
}
.mobileMenu {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid lightgray;
  animation: mobileMenu 0.5s;
  z-index: 1;
}

.mobileMenu .mobile-btn {
  display: block;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 10px 20px;
  text-decoration: none;
  color: gray;
  font-weight: bold;
}
.mobileMenu .active, 
.mobile-btn :hover {
  background-color: #0089BF;
  color: white;
}

.grid-inflatables {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 30px;
  line-height: 30px;
  background-color: #0089BF;
  border-top: 1px solid white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.grid-inflatables .inflatables-btns {
  color: white;
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.5px;  
  transition: 0.5s;
}
.grid-inflatables .inflatables-btns:hover {
  letter-spacing: 1.2px;
}

 
@media screen and (max-width:800px) {
  .header .navBtns, 
  .header .mobileMenu, 
  .header .grid-inflatables {
      display: none;
  }
  .header {
    width: 100vw;
    height: 60px;
    grid-template-columns: 50% auto;
  }
  .header img {
    height: 40px;
    object-fit: contain;

    /* border: 1px solid red; */
  }
  .nav-mobile-menu {
    display: block;
    margin-right: 30px;
  }
  
}

@keyframes mobileMenu {
  from {
    top:-100%
  }
  to {
    top:60px;
  }
}

.faq {
  display: block;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: 0.5s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.faq:hover {
  border: 1px solid #0089BF;
}
.faq .question {
  font-weight: bold;
  color: #072A40;
  font-size: 20px;
  margin: 0;
}
.faq .answer {
  margin: 0;
}

.carousel-inflatables {
  display: flex;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% + 60px);
}
.carousel-inflatables .iconChev{
  color: #0089BF;
  font-size: 30px;
}
@media screen and (max-width:800px) {
  .carousel-inflatables {
    padding-left: 0;
  }
  .carousel-inflatables .inflatable {
    width: 350px;
  }
}

/* ======== HOME ========= */

.home .container1 {
  background-image: url("/src/images/stock1.png");
  background-color: #cccccc; 
  height: 600px;
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover;
  width: 98%;
  margin: 10px auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.home .inflatables {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
}

.home .container1 h1 {
  font-size: 3em;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Adjust the shadow values as needed */
}
.home .container1 h2 {
  font-size: 30px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Adjust the shadow values as needed */
}
.home .container2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.home .container2 .carousel-inflatables {
  width: 100%;
}
.home .container3 {
  width: 90%;
  margin: 60px auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 600px auto;
  gap: 40px;
  align-items: center;
}
.home .container3 img {
  width: 100%;
}
.home .container4 {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  text-align: center;
  justify-content: center !important;
  align-items: center !important;
}
.home .container4 .eventTypes {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
}
.home .container4 img {
  width: 90%;
  margin: auto;
}
.home .container4 h3 {
  font-size: 25px;
  font-weight: bold;
  color: #072A40;
  margin: 10px;
}
.home .container4 p {
  width: 90%;
  margin: auto;
}


.home .container5 {
  width: 90%;
  margin: 100px auto;
  padding: 20px;
  display: grid;
  grid-template-columns: auto 600px;
  gap: 40px;
  align-items: center;
  text-align: right;
}
.home .container5 img {
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.home .txt-container5 {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.home .container6 {
  width: 90%;
  margin:auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  margin-bottom: 80px;
}

.home .container7 {
  background-color: #0089BF;
  text-align: center;
  padding: 20px;
  margin-top: 40px;
  display: none;
}
.home .container7 h3 {
  color:  white;
  font-size: 40px;
  font-weight: bold;
  margin-top: 20px;
}
.home .container7 .grid-comments {
  display: flex;
  max-width: 90%;
  margin: 40px auto;
  gap: 40px;
}

@media screen and (max-width:800px) {
  .home .container1 {
    width: 100%;
    margin: -40px auto 40px auto;
    height: 400px;
    align-items: center;
  }
  .home .container1 h1 {
    font-size: 30px;
    text-align: center;
  }
  .home .container1 h2 {
    font-size: 20px;
    text-align: center;
  }
  .home .container2 .inflatable #description {
    max-width: 300px;
  }
  .home .container2 .inflatable {
    white-space: normal;
  }
  .home .container2 .iconChev {
    display: none;
  }
  .home .container3 {
    display: block;
    text-align: center;
    width: 100%;
  }
  .home .container3 .btn-contact {
    margin: 40px auto 0 auto;
  }
  .home .container4 {
    display: block;
    width: 100%;
  }
  .home .container4 .eventTypes {
    margin-bottom: 30px;
  }
  .home .container5 {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: -10px;
  }
  .home .container5 .txt-container5 {
    align-items: center;
    text-align: center;
  }
  .home .container5 .btn-contact {
    margin-bottom: 20px;
  }
  .home .container6 {
    display: block;
    width: 100%;
  }
  .home .container6 .check p {
    margin: auto;
    margin-bottom: 30px;
  }
  .home .container7 h3 {
    font-size: 30px;
  }
  .home .container7 .grid-comments {
    display: block;
    max-width: 100%;
  }
  .home .container7 .grid-comments .comment{
    margin-bottom: 30px;
  }
  .home .newsLetter {
    text-align: center;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .home .newsLetter h2{
    margin-top: -40px;
  }
  .home .newsLetter input {
    width: 90%;
  }

  .footer{
    display: block;
  }
  .footer #right {
    text-align: left;
    margin-top: 20px;
  }
  .footer .iconFacebook {
    margin-left: 0;
  }
  .footer .menu {
    gap: 0;
    max-width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }
}



/* ========= SERVICES ===========  */
.services .container1 {
  width: 100%;
  margin: 0;
  padding: 20px 5%;
  display: grid;
  grid-template-columns: 600px auto;
  gap: 40px;
  align-items: center;
  background-color: #0089BF;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.services .container1 img {
  width: 100%;
}
.services .container1  p {
  color: white;
}
.services .container1 button {
  display: block;
  width: 220px;
  height: 40px;
  border: none;
  background-color: white;
  color: #0089BF;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  transition: 0.5s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px;
}
.services .container1 button:hover {
  background-color: #0089BF;
  color: white;
}


.services .container2 {
  width: 90%;
  margin: 60px auto;
  padding: 20px;
}
.services .container2 .subTitle {
  text-align: center;
  font-size: 40px;
  margin: 0 auto 50px auto;
}

.services .container2 .reasons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  text-align: center;
  margin: auto;
}
.services .container2 p {
  max-width: 90%;
  margin: auto;
}
.services .container2  .iconReason {
  font-size: 70px;
  color: #0089BF;
}
.services .container3 {
  width: 100%;
  margin: 0;
  padding: 20px 5%;
  display: grid;
  grid-template-columns: auto 600px;
  gap: 40px;
  align-items: center;
  text-align: right;
}
.services .container3 img {
  width: 90%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.services .container3 .btn-contact {
  margin-left: auto;
}
.services .container4 {
  width: 90%;
  margin: 40px auto;
  padding: 20px;
}
.services .container4 .subTitle {
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width:800px) {
  .services .container1 {
    display: block;
    margin-top: -50px;
    text-align: center;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .services .container1 img {
    margin-bottom: 30px;
  }
  .services .container1 .btn-checkInflatables {
    display: block;
    margin: 20px auto;
  }
  .services .container2 {
    margin-top: 30px;
  }
  .services .container2 .reasons {
    display: block;
  }
  .services .container2 .subTitle {
    font-size: 30px;
  }
  .services .container3 {
    display: block;
    text-align: center;
  }
  .services .container3 .btn-contact {
    margin: 20px auto;
  }
  .services .container4 {
    width: 100%;
    padding: 10px;
  }
  .services .container4 .subTitle{
    max-width: 90%;
    margin: 0 auto 20px auto;
  }
  .services .newsLetter {
    text-align: center;
  }
  .services .newsLetter h2{
    margin-top: -40px;
  }
  .services .newsLetter input {
    width: 90%;
  }
  .services .newsLetter {
    text-align: center;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

/*  ======== CONTACT  ========  */
.contact .container1 {
  width: 100%;
  margin: 40px auto;
  padding: 20px 5%;
  display: grid;
  grid-template-columns: 600px auto;
  gap: 40px;
  align-items: center;
}
.contact .container1 .phone-mail {
  font-size: 20px;
  font-weight: bold;
  color: #0089BF;
}
.phone-mail .icon {
  margin-right: 10px;
}

.contact .container1 .form {
  
}
.contact .container1 .form input{
  display: block;
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  padding-left: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  outline: none;
}
.contact .container1 .form textarea{
  display: block;
  width: 100%;
  height: 100px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-top: 5px;
  border: 1px solid lightgray;
  border-radius: 10px;
  resize: none;
  outline: none;
}
.contact .container1 button {
  display: block;
  background-color: #0089BF;
  color: white;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.contact .container1 button:hover {
  background-color: white;
  color: #0089BF;
}
.contact .container1 .details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact .map{
  display: block;
  width: 90%;
  height: 300px;
  max-height: 300px;
  object-fit: cover;
  margin: auto;
  margin-bottom: 50px;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media screen and (max-width:800px) {
  .contact .container1 {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: 0 auto;
    gap: 40px;
    padding: 0;
  }
  .contact .container1 .details {
    gap: 0px;
    text-align: center;
  }
  .contact .container1 .details .phone-mail {
    font-size: 20px;
    text-align: center;
  }
  .contact .container1 .form {
    width: 100%;
    margin: 20px auto;
  }
  .contact .container1 .subTitle {
    text-align: center;
  }
  .contact .container1 .form button {
    width: 100%;
  }
  .contact .map {
    height: 400px;
  }
}



/* ======== BOOKING INFLATABLE ========== */
.booking-inflatable .main-container{
  display: grid;
  grid-template-columns: auto 400px;
  padding: 20px;
  height: calc(100vh - 90px);
}
.booking-inflatable .main-container #left{
  padding: 0 10px;
  overflow-y: auto;
  width: 90%;
  margin: 0 auto;
}
.booking-inflatable .main-container #left img {
  display: block;
  margin: auto;
  width: 100%;
  max-height: 500px;
  margin-bottom: 30px;
  object-fit: contain;
}
.booking-inflatable .main-container #name {
  font-size: 30px;
  font-weight: bold;
  color: #072A40;
}
.booking-inflatable .main-container #price {
  background-color: #0089BF;
  color: white;
  width: fit-content;
  padding: 5px 20px;
  border-radius: 5px; 
}
.booking-inflatable .main-container .name-price {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.booking-inflatable .main-container #dimentions {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 10%;
  margin-bottom: 8px;
}
.booking-inflatable .main-container #dimentions .title{
  font-size: 17px;
  color: black;
  font-weight: 600;
  text-align: center;
}
.booking-inflatable .main-container #dimentions .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.booking-inflatable .main-container .dimention {
  border: 1px solid lightgray;
  border-radius: 5px;
  text-align: center;
}
.booking-inflatable .main-container .dimention .value {
  font-size: 15px;
  margin: 0;
}
.booking-inflatable .main-container .dimention .type {
  font-size: 15px;
  color: #0089BF;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
}
.booking-inflatable .main-container #dimentions .wetDry{
  text-align: center;
  /* font-size: 20px; */
}
.iconWet{
  color: rgb(0, 217, 255);
}
.iconDry{
  color: rgb(255, 213, 0);
}

.booking-inflatable .main-container #right{
  border-left: 1px solid rgb(228, 228, 228);
  padding: 10px;
  overflow-y: scroll;
  /* border:1px solid red; */
}

.booking-inflatable .recommendations {
  max-width: 90%;
  margin: 20px auto 100px auto;
  border-top: 1px solid lightgray;
  padding-top: 40px;
}
.booking-inflatable #right .form {
  width: 90%;
  margin: auto;
  display: none;
}
.booking-inflatable #right .form input{
  display: block;
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  padding-left: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  outline: none;
}

.booking-inflatable #right .btn-book {
  display: block;
  background-color: #0089BF;
  color: white;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.booking-inflatable #right .btn-book:hover {
  background-color: white;
  color: #0089BF;
}

.booking-inflatable #rentalInformation {
  color: gray;
  font-size: 1rem;
  margin: 20px 0;
}
.booking-inflatable #rentalInformation p{
  color: black;
  font-weight: 600;
}

.booking-inflatable .inflatables {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
}

@media screen and (max-width:800px) {
  .booking-inflatable .main-container {
    display: block;
    height: fit-content;
    padding: 0;
  }
  .booking-inflatable .main-container #left {
    margin-top: -40px;
    width: 100%;
    padding: 15px;
  }
  .booking-inflatable .main-container #left img {
    max-width: 100%;
    margin-top: 20px;
  }
  .booking-inflatable .name-price {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .booking-inflatable .main-container #left #dimentions{
    margin: auto;
    display: block;
  }
  .booking-inflatable .main-container #left #dimentions .dimention{
    margin-bottom: 20px;
  }

  .booking-inflatable .recommendations  {
    text-align: center;
    max-width: 100vw;
  }
  .booking-inflatable .carousel-inflatables {
    width: 100%;
  }
  .booking-inflatable .recommendations .carousel-inflatables .inflatables{
    padding-left: 20px;
  }
  .booking-inflatable .recommendations .carousel-inflatables .inflatables .inflatable{
    white-space: normal;
  }  
  .booking-inflatable .recommendations .iconChev {
    display: none;
  }
  .booking-inflatable #right {
    border: 0px !important;
  }
}


.houseContact {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.houseContact h2{
  font-size: 30px;
  font-weight: bold;
  color: #0089BF;
}
.houseContact .btn-contact {
  margin: 20px auto;
}
.houseContact .btn-property {
  color: #0089BF;
  background-color: transparent;
  border: none;
}
.houseContact .btn-property:hover {
  background-color: white;
  color: #0089BF;
}
/* ========= INFLATABLES PAGE ========= */

.inflatables-page .container1{
  width: 100%;
  padding: 20px;
  justify-content: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.inflatables-page .container1 .top-nav {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.inflatables-page .container1 .top-nav select {
  border: none;
  background-color: transparent;
  color: gray;
  font-size: 20px;
  font-weight: 600;
  outline: none;
}
.inflatables-page .inflatables {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 5px;
  width: 100%; /* Adjust the width percentage as needed */
  margin: 0 auto; /* Center the grid horizontally */
  position: relative;
}

.inflatables-page .inflatables .inflatable{
  margin-bottom: 5px;
}


@media screen and (max-width:800px) {
  .inflatables-page {
    margin-top: -50px;
  }
  .inflatables-page .container1 {
    width: 100%;
    max-width: 400px;
  }
  /* .inflatables-page .inflatables {
    width: 100%;
    display: flex;
    flex-direction: column;
  } */
  .inflatables-page .inflatables .inflatable{
    display: block;
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
  }
  .inflatables-page .inflatables .inflatable #description{
    white-space: normal;
    min-width: 100%;
  }
}


/* =====  INVOICE PAGE  ======= */
/* .invoice-page {
  margin-bottom: 50px;
}
.invoice {
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding:40px;
  margin-top: 20px;
  width: fit-content;
  margin: auto;
}
.invoice .headerImg {
  width: 200px;
}
.invoice #left img {
  width:70%;
  margin-bottom: 40px;
}
.invoice p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 20px;
}
.invoice .grid {
  display: grid;
  grid-template-columns: 500px 500px;
  gap: 30px;
  align-items: center;
}
.invoice #total {
  font-size: 40px;
  color: gray;
  font-weight: bold;
  text-align: center;
}
.invoice .grid-check {
  display: flex;
  gap: 10px;
  align-items: center;
}
.invoice .grid-check input{
  margin-top: -5px;
}
.invoice #disclaimer {
  font-size: 13px;
}
.invoice h4, .invoice h3 {
  font-size: 15px;
  color: #0089BF;
}
.invoice #confirmation p {
  margin-bottom: 15px;
}

@media screen and (max-width:800px) {
  .invoice-page {
    margin-top:-60px ;
  }
  .invoice {
    margin-top: 0;
  }
  .invoice .headerImg {
    margin: auto;
    display: block;
  }
  .invoice .grid {
    display: block;
  }
  .invoice #left img {
    width: 100%;
  }
  .invoice #total {
    margin-top: 30px;
  }
  .invoice #btnPay {
    width: 100%;
    height: 45px;
    font-size: 20px;
  }
  .invoice .paymentConfirmation {
    flex-direction: column;
    text-align: center;
  }

  .invoice #confirmation p {
   line-height: 20px;
  }

} */

.invoice-page {
  /* border: 1px solid red; */
  margin-top: -110px;
  padding: 30px;
}
.invoice-page .headerImg {
  height: 50px;
}

.invoice-page p {
  margin: 0;
}

.invoice-page .main-grid {
  display: grid;
  grid-template-columns: 60% auto;
}
.invoice-page .main-grid #left, 
.invoice-page .main-grid #right {
  padding: 20px;
}
.invoice-page .main-grid #left{
  border-right: 1px solid lightgray;
}

.invoice-page .main-grid h2{
  margin:30px 0 10px 0;
  border-bottom: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  color: gray;
}


.invoice-page .main-grid .inflatable-row {
  border: 1px solid lightgray;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.invoice-page .main-grid .inflatable-row img{
  width: 100px;
  height: 80px;
  object-fit: contain;
}




.invoice-page .main-grid #right .balance {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 5px;
}
.invoice-page .main-grid #right .balance:hover {
  background-color: lightgray;
}

.invoice-page .main-grid #right .balance-payment {
  text-align: center;
  margin-top: 30px;
}
.invoice-page .main-grid #right .balance-payment p {
  color: gray;
}
.invoice-page .main-grid #right .balance-payment h3{
  font-size: 80px;
  font-weight: 400;
  color: gray;
  margin-top: -10px;
}


.paymentLoader {
  
}
.paymentLoader img {
  width: 80%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: auto;
}
.paymentLoader p {
  text-align: center;
  font-size: 20px;
}



.invoice-page .confirmation {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
}
.invoice-page .confirmation .iconCheck {
  margin-top: -100px;
}

.invoice-page .confirmation h2 {
  font-size: 40px;
  font-weight: bold;
  border: none;
}
.invoice-page .confirmation p {
  font-size: 20px;
  max-width: 50%;
  text-align: center;
}

@media screen and (max-width:900px){
  .invoice-page {
    padding: 0
  }
  .invoice-page .main-grid {
    display: block;
    padding: 0;
  }
  .invoice-page .main-grid #left{
    border: none;
  }
  .invoice-page .inflatable-row {
    flex-direction: column;
  }
  .invoice-page .main-grid .inflatable-row img{
    width: 150px;
  }
}




/* ====== CONTRACT PAGE ======  */
.wrapper-contract {
  padding: 20px;
  color: gray;
  margin-top: 0;
}
.wrapper-contract h2 {
  color: #0089BF;
  font-size: 25px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
}
.wrapper-contract li {
  margin-bottom: 10px;
}
@media screen and (max-width: 800px) {
    .wrapper-contract {
      padding: 0 15px;
      margin-top: -20px;
      margin-bottom: 20px;
    }
}





/* ========= CART PAGE ============*/
.cart-page .content{
  min-height: 60vh;
  padding: 30px;
}
.cart-page .content h2 {
  color: gray;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
}
.cart-page .emptyCartIcon {
  font-size: 60px;
  display: block;
  color: #0089BF;
  text-align: center;
}
.cart-page #notice {
  display: block;
  margin: auto;
  font-size: 40px;
  text-align: center;
  max-width: 60%;
}

.cart-page .row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 50px;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
}
  .cart-page .row p {
  text-align: center;
  margin-bottom: 0;
}
.cart-page .row img {
  height: 100px;
  object-fit: contain;
}
.cart-page .row .iconTrash {
  text-align: center;
  font-size: 20px;
  color: gray;
  transition: 0.3s;
}
.cart-page .row .iconTrash:hover {
  color: red;
}

.cart-page .content button {
  display: block;
  padding: 3px 20px;
  background-color: #0089BF;
  color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 20px 0 20px auto;
  transition: 0.4s;
}

.cart-page .content button:hover {
  background-color: white;
  color: #0089BF;
  border: 1px solid #0089BF;
}





/* ======= CHECKPUT PAGE ======== */
.checkout-page {
  padding: 30px;
  padding-bottom: 0;
}
.checkout-page .content h2 {
  color: gray;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 20px;
}
.checkout-page .main-grid {
  display: grid;
  grid-template-columns: auto 30%;
  margin-bottom: 50px;
  height: 80vh;
}

.checkout-page .main-grid #left, 
.checkout-page .main-grid #right {
  padding: 20px;
  overflow:scroll;
}


.checkout-page .main-grid #left {
  border-right: 1px solid lightgray;
  padding-bottom: 50px;
}

.checkout-page .specific-time {
  margin-bottom: 10px;
}
.checkout-page .labels h4 {
  font-size: 15px;
}
.checkout-page .labels p {
  font-size: 12px;
  margin: 0;
}
.checkout-page .grid-specificTime {
  display: grid;
  grid-template-columns: 100% 120px;
  margin-top: 10px;
}
.checkout-page .grid-specificTime select {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #0089BF;
  padding-left: 10px;
  height: 30px;
  outline: none;
  color: gray;
}
.checkout-page .grid-specificTime input{
  width: 130px;
  height: 30px;
  margin-left: -130px;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #0089BF;
  border-bottom: 1px solid #0089BF;
  border-right: 1px solid #0089BF;
  background-color: #0089BF;
  color: white;
  outline: none;
  padding-left: 20px;
}

.checkout-page .two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.checkout-page .switch {
 transform: scale(0.7);
}
.checkout-page .switch-col {
  display: grid;
  grid-template-columns: 70px auto;
  gap: 0px;
  align-items: center;
  border-top: 1px solid lightgray;
  padding: 20px 10px;
}
.checkout-page .switch-col h4 {
  color: black;
  font-size: 15px;
}
.checkout-page .switch-col p {
  font-size: 12px;
  margin-bottom: 0;
}

.checkout-page  #last {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgray;
}


.checkout-page .delivery {
  display: grid;
  grid-template-columns: 100% 120px;
  margin-bottom: 15px;
  margin-top: 10px;
  height: 30px;
  transition: 0.5s;
}
.checkout-page .delivery input{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #0089BF;
  padding-left: 10px;
  height: 30px;
  outline: none;
  color: gray;
}
.checkout-page .delivery .suggestions {
  transition: 0.5s;
  height: fit-content;
  overflow-y: scroll;
  position: relative;
  background-color: white;
}
.checkout-page .delivery button{
  height: 30px;
  width: 120px;
  margin-left: -120px;
  border: none;
  background-color: #0089BF;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.checkout-page #specific-time,
.checkout-page #delivery-address {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkout-page .floor-type {
  padding:20px 10px;
  border-top: 1px solid lightgray;
}
.checkout-page .floor-type .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 0;
}
.checkout-page .floor-type button {
  background-color: white;
  color: gray;
  border: 1px solid #0089BF;
  border-radius: 5px;
  height: 30px;
  transition: 0.5s;
}
.checkout-page .floor-type button:hover {
  background-color: #0089BF;
  color: white;
}

.checkout-page .floor-type .selected{
  background-color: #0089BF;
  color: white;
}

.checkout-page .row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 10px;
  /* border: 1px solid lightgray; */
  border-radius: 10px;
  align-items: center;
}
.checkout-page .row p {
  margin-bottom: 0;
}
.checkout-page .row img {
  height: 100px;
  object-fit: contain;
}

.checkout-page .row .title {
  text-align: left;
  margin-left: 5px;
}
.checkout-page .row .description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left!important;
}
.checkout-page .row .price {
  text-align: right;
}



.checkout-page #total {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.checkout-page .promoCode {
  display: block;
  width: 200px;
  height: 30px;
  padding-left: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
  text-transform: uppercase;
  color: #0089BF;
  font-weight: bold;
}
.checkout-page .noTopBorder {
  border-top: none;
  padding-top: 0;
}


.checkout-page .payment-method {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.checkout-page .btn-payment {
  display: flex;
  flex-direction: row;
  gap: 15px;
  border: 1px solid lightgray;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
}

.checkout-page .payment-method p {
  margin-bottom: 0;
}

.checkout-page .payment-method .selected  {
  display: flex;
  flex-direction: row;
  gap: 15px;
  color: white;
  border: 1px solid #0089BF;
  background-color: #0089BF;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
}
.checkout-page .payment-method .selected  p{
  color: white;
}
.checkout-page .deposit button {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 5px 10px;
  margin: 5px 0;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
}
.checkout-page .deposit .selected {
  background-color: #0089BF;
  color: white;
}

.checkout-page .form {
  border-top: 1px solid lightgray;
  padding: 20px 0;
}
.checkout-page .form input {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  padding: 5px 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
}.checkout-page .form input[type='text'] {
  text-transform: capitalize;
}
.checkout-page .alert-form {
  display: flex;
  gap: 10px;
  background-color: rgba(249, 210, 210, 0.578);
  border: 1px solid #ff00002a;
  padding: 5px 15px;
  margin-bottom: 3px;
  border-radius: 5px;
  font-size: 12px;
  color: red;
}
.checkout-page .alert-form p {
  margin-bottom: 0;
  color: red;
} 


.checkout-page .booking-methods button {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #0089BF;
  color: #0089BF;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 20px;
}
.checkout-page .booking-methods button:hover {
  background-color: #0089BF;
  color: white;
  border: 1px solid #0089BF;
}


.checkout-page .confirmation {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
}

.checkout-page .confirmation h2 {
  font-size: 40px;
  font-weight: bold;
}
.checkout-page .confirmation p {
  font-size: 20px;
  max-width: 50%;
  text-align: center;
}

.checkout-page #title-paymentDetails {
  display: none;
}

  .booking-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin: auto
}
.booking-actions p{
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}


.booking-actions .btn-action {
  background-color: white;
  color: gray;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 3px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.booking-actions .btn-action:hover {
  background-color: #0089BF;
  color: white;
  border: 1px solid transparent;
}
.booking-actions .btn-action:hover p {
  color: white !important; /* Add this to ensure the text color changes */
}

.iconBookingActions {
  transition: 0.8s;
}
.booking-actions .btn-action:hover .iconBookingActions {
  margin-left: 15px;
}


.booking-actions .confirmation {
  position: fixed;
  left: 50%; top:50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 500px;
  height: 300px;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.booking-actions .confirmation .iconCart{
  font-size: 40px;
  color: white;
  background-color: #0089BF;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50px;
  text-align: center;
  display: block;
  animation: entrance 0.5s ease-in-out forwards, happy 1s alternate;
}
.booking-actions .confirmation h2 {
  font-size: 20px;
  color: gray;
  margin-bottom: 0;
}
.booking-actions .confirmation p {
  font-size: 40px;
  margin-top: -10px;
}

.booking-actions .confirmation button {
  background-color: #0089BF;
  color: white;
  border: 1px solid transparent;
  padding: 5px 30px;
  border-radius: 10px;
  transition: 0.5s;
  animation-name: fadeEffect;
  animation-duration: 1.5s;
}
.booking-actions .confirmation button:hover {
  background-color: white;
  border: 1px solid #0089BF;
  color: #0089BF;
}


@keyframes entrance {
  0% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
}
100% {
    opacity: 1;
    transform: translateY(0) scale(1);
}
}

@keyframes happy {
  0% {
      transform: translateY(0) rotate(0) scale(1);
  }
  25% {
      transform: translateY(-10px) rotate(-10deg) scale(1.1);
  }
  50% {
      transform: translateY(0) rotate(0) scale(1);
  }
  75% {
      transform: translateY(-5px) rotate(10deg) scale(1.1);
  }
  100% {
      transform: translateY(0) rotate(0) scale(1);
  }
}

@keyframes fadeEffect {
  0% {
      opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@media screen and (max-width:600px) {
  .booking-actions .confirmation {
    width: 95%;
  }
  .booking-actions .confirmation p {
    font-size: 30px;
  }

  .cart-page #notice { 
    font-size: 20px;
  }

  .checkout-page {
    padding: 0;
  }
  .checkout-page h2 {
    text-align: center;
    margin-bottom: 0px !important;
    margin-top: -30px;
  }
  .checkout-page .main-grid {
    display: block;
    height: auto;
  }
  .checkout-page .main-grid #left {
    border: none;
  }
  .checkout-page .two-col {
    display: block;
  }
  .checkout-page .floor-type .grid {
    grid-template-columns: 1fr;
    gap: 5px;
  }
  .checkout-page .floor-type .grid button{
    height: 40px;
  }
  .checkout-page .row {
    display: block;
  }
  .checkout-page .row .title{
    margin-left: 0;
  }
  .checkout-page .row .price{
    margin-top: -40px;
    margin-bottom: 30px;
  }
  .checkout-page #total {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    justify-content: center;
    gap: 10px;
  }
  .checkout-page #total input{
    text-align: center;
  }
  .checkout-page #total p {
    margin: 0;
  }
  
  .footer h2 {
    /* margin-top: -5px !important;
    margin-left: -90px !important;
    margin-bottom: 10px !important; */
  }
}

@media screen and (max-width:800px) {
  .cart-page .row {
    grid-template-columns: 1fr;
    gap: 5px;
  }
  .checkout-page .confirmation {
    padding: 10px;
    gap: 20px;
  }
  .checkout-page .confirmation p, 
  .checkout-page .confirmation h2 {
    max-width: 600px;
  }
  .checkout-page .confirmation p {
    font-size: 20px;
  }
  .checkout-page .confirmation h2 {
    font-size: 20px;
  }
}